if (!window.PF) {
    PF = {};
}

if (!window.PF.common) {

    PF.common = {
        footer: '<b>Dados Pessoais</b><br />A Autoridade Tribut&aacute;ria e Aduaneira (AT) trata dados pessoais no &acirc;mbito das suas atribui&ccedil;&otilde;es, designadamente as constantes do artigo 2.&ordm;, n.&ordm; 2, do Decreto-Lei n.&ordm; 118/2011, de 5 de dezembro. Para cumprimento do Regulamento Geral sobre a Prote&ccedil;&atilde;o de Dados (RGPD) e da Lei n.&ordm; 58/2019, de 8 de agosto, o Despacho n.&ordm; 13949-A/2022 designou a mestre In&ecirc;s Oliveira Andrade de Jesus como <a href="@info-url@/pt/at/Pages/Encarregado_protecao_dados.aspx" title="encarregada da prote&ccedil;&atilde;o de dados">encarregada da prote&ccedil;&atilde;o de dados</a> da AT. Os titulares dos dados podem contactar a encarregada da prote&ccedil;&atilde;o de dados da AT sobre todas as quest&otilde;es relacionadas com o tratamento dos seus dados pessoais e com o exerc&iacute;cio dos direitos que lhe s&atilde;o conferidos pelo RGPD atrav&eacute;s do endere&ccedil;o eletr&oacute;nico <a href="mailto:epd@at.gov.pt">epd@at.gov.pt</a>. <br />Saiba mais sobre a <a href="@info-url@/pt/quem_somos/privacidade/Pages/privacidade.aspx" title="Pol&iacute;tica de prote&ccedil;&atilde;o de dados pessoais e privacidade">Pol&iacute;tica de prote&ccedil;&atilde;o de dados pessoais e privacidade</a>.',
    }

}

// Necessário para os templates presentes no pfstatic
window.addEventListener('load', function () {
    const footerElem = document.getElementById("footer-rgpd-content");
    if(footerElem !== null){
        footerElem.innerHTML = PF.common.footer;
    }
});
